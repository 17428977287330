import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";

import { Privacy } from "../components/sections/Privacy";
import { Footer } from "../components/sections/Footer";

const PrivacyPage = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy - Boost your ROAS" />

      {/* <Navigation /> */}
      <Privacy />
      <Footer />
    </Layout>
  );
};

export default PrivacyPage;
